import React from 'react'
import PortableText from "react-portable-text"
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import Layout from '../components/layout/layout'
import { graphql, Link } from 'gatsby'
import Seo from '../components/utils/seo'

const PolicyPage = ({ data }) => {
    const { policy } = data
    const serializers = {
        blockImage(props) {
            console.log(props)
            const caption = props.caption
            const imageAssetId = props.image.asset.id
            const imageData = getGatsbyImageData(imageAssetId, { maxWidth: 1024 }, { projectId: "57wf6c5n", dataset: "production" })
            return (
                <div className='mb-2'>
                    <GatsbyImage image={imageData} alt={props.alt ? props.alt : ""} />
                    <p className='text-gray-700 italic'>{caption}</p>
                </div>
            )
        },
        blockProduct(props) {
            const product = props.productWithVariant.product.store
            const lowPrice = Number(product.priceRange.minVariantPrice)
            const highPrice = Number(product.priceRange.maxVariantPrice)
            return (
                <Link key={product.title} className='group flex border border-gray-600 w-max pr-4 my-8 items-center' to={`/products/${product.slug.current}`}>
                    <div className='overflow-hidden max-h-36 w-16'>
                        <img className='transform hover:scale-105 transition-transform duration-700' width="" src={product.previewImageUrl} alt={product.title} />
                    </div>
                    <div className='pl-2'>
                        <p className='text-xl group-hover:underline'>{product.title}</p>
                        {lowPrice !== highPrice ? (
                            <p>From £{lowPrice.toFixed(2)}</p>
                        ) : (
                            <p>£{lowPrice.toFixed(2)}</p>
                        )}
                    </div>
                </Link>
            )
        },
        ul: ({ children }) => <ul className="list-disc list-inside">{children}</ul>,
        link(props) {
            return (
                <a href={props.href} rel='noopener noreferrer' target="_blank">{props.children}</a>
            )
        },
        internalLink(props) {
            return (
                <Link to={`/${props.reference.slug.current}`}>{props.children}</Link>
            )
        },
        annotationLinkInternal(props) {
            let link
            const linkFinder = () => {
                switch (props.reference._type) {
                    case 'collection':
                        link = `/collections/${props.reference.slug.current}`;
                        break;
                    case 'post':
                        link = `/posts/${props.reference.slug.current}`;
                        break;
                    case 'page':
                        link = `/${props.reference.slug.current}`;
                        break;
                    case 'product':
                        link = `/products/${props.reference.store.slug.current}`;
                        break;
                    default:
                        link = `/`
                }
            }
            linkFinder()
            return (
                <Link to={link}>{props.children}</Link>
            )
        },
        annotationLinkExternal(props) {
            console.log(props)
            return (
                <a href={props.url} rel="noopener noreferrer" target={props.newWindow ? "_blank" : "_self"} >{props.children}</a>
            )
        },
        annotationLinkEmail(props) {
            console.log(props)
            return (
                <a href={`mailto:${props.email}`}>{props.children}</a>
            )
        }
    }
    return (
        <Layout>
            <Seo title={data.policy.title}>
                <meta name="robots" content="noindex" />
            </Seo>
            <div className='container max-w-screen-lg mx-auto my-12 px-8 xl:px-0'>
                <h1>{data.policy.title}</h1>
                <PortableText className='my-12' content={policy._rawPageContent.body} serializers={serializers} />
            </div>

        </Layout>
    )
}

export default PolicyPage

export const query = graphql`
    query sanityPolicyQuery($slug: String) {
        policy: sanityPolicy(slug: {current: {eq: $slug}}) {
            title
            _rawPageContent(resolveReferences: {maxDepth: 10})
            slug {
                current
                }
          }
    }
`
